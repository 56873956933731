import { SITE_TITLE } from '~/config'

export const Wordmark = () => (
  <svg
    width="268"
    height="106"
    viewBox="0 0 268 106"
    fill="none"
    className="wordmark"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{SITE_TITLE}</title>
    <path d="M66.6 53.52C66.6 62.72 63.35 70.57 56.85 77.07C50.35 83.57 42.5 86.82 33.3 86.82C24.1 86.82 16.25 83.57 9.75 77.07C3.25 70.57 0 62.72 0 53.52C0 44.32 3.25 36.47 9.75 29.97C16.25 23.47 24.1 20.22 33.3 20.22C42.5 20.22 50.35 23.47 56.85 29.97C63.35 36.47 66.6 44.32 66.6 53.52ZM33.3 29.73C26.72 29.73 21.11 32.05 16.47 36.69C11.83 41.33 9.51 46.94 9.51 53.52C9.51 60.1 11.83 65.71 16.47 70.35C21.11 74.99 26.72 77.31 33.3 77.31C39.88 77.31 45.49 74.99 50.13 70.35C54.77 65.71 57.09 60.1 57.09 53.52C57.09 46.94 54.77 41.33 50.13 36.69C45.49 32.05 39.88 29.73 33.3 29.73V29.73Z" />
    <path d="M85.6301 82.06C85.6301 83.37 85.1601 84.49 84.2301 85.42C83.3001 86.35 82.1801 86.82 80.8701 86.82C79.5601 86.82 78.4401 86.35 77.5101 85.42C76.5801 84.49 76.1101 83.37 76.1101 82.06V24.98C76.1101 23.67 76.5801 22.55 77.5101 21.62C78.4401 20.69 79.5601 20.22 80.8701 20.22C82.1801 20.22 83.3001 20.69 84.2301 21.62C85.1601 22.55 85.6301 23.67 85.6301 24.98V26.23C90.3501 22.23 95.9001 20.22 102.28 20.22C109.49 20.22 115.66 22.78 120.77 27.89C121.84 28.96 122.81 30.09 123.68 31.28C124.55 30.09 125.52 28.96 126.59 27.89C131.7 22.78 137.87 20.22 145.08 20.22C152.29 20.22 158.46 22.78 163.57 27.89C168.69 33 171.24 39.17 171.24 46.38V82.06C171.24 83.37 170.77 84.49 169.84 85.42C168.91 86.35 167.79 86.82 166.48 86.82C165.17 86.82 164.05 86.35 163.12 85.42C162.19 84.49 161.72 83.37 161.72 82.06V46.38C161.72 41.78 160.09 37.86 156.84 34.61C153.59 31.36 149.66 29.73 145.07 29.73C140.48 29.73 136.55 31.36 133.3 34.61C130.05 37.86 128.42 41.79 128.42 46.38V82.06C128.42 83.37 127.95 84.49 127.02 85.42C126.09 86.35 124.97 86.82 123.66 86.82C122.35 86.82 121.23 86.35 120.3 85.42C119.37 84.49 118.9 83.37 118.9 82.06V46.38C118.9 41.78 117.27 37.86 114.02 34.61C110.77 31.36 106.84 29.73 102.25 29.73C97.6601 29.73 93.7301 31.36 90.4801 34.61C87.2301 37.86 85.6001 41.79 85.6001 46.38V82.06H85.6301Z" />
    <path d="M183.22 24.98C183.22 23.67 183.68 22.55 184.62 21.62C185.55 20.69 186.67 20.22 187.98 20.22C189.29 20.22 190.41 20.69 191.34 21.62C192.27 22.55 192.74 23.67 192.74 24.98V28.85C198.69 23.1 205.82 20.23 214.15 20.23C222.48 20.23 229.96 23.25 236 29.3C242.04 35.35 245.07 42.63 245.07 51.15V82.07C245.07 83.38 244.6 84.5 243.67 85.43C242.74 86.36 241.62 86.83 240.31 86.83C239 86.83 237.88 86.36 236.95 85.43C236.02 84.5 235.55 83.38 235.55 82.07V51.15C235.55 45.24 233.46 40.2 229.28 36.02C225.1 31.84 220.05 29.75 214.15 29.75C208.25 29.75 203.2 31.84 199.02 36.02C194.84 40.2 192.75 45.25 192.75 51.15V82.07C192.75 83.38 192.28 84.5 191.35 85.43C190.42 86.36 189.3 86.83 187.99 86.83C186.68 86.83 185.56 86.36 184.63 85.43C183.7 84.5 183.23 83.38 183.23 82.07V24.98H183.22Z" />
    <path d="M267.74 5.95001C267.74 7.58001 267.16 8.97001 265.99 10.14C264.82 11.31 263.42 11.89 261.8 11.89C260.18 11.89 258.78 11.31 257.61 10.14C256.44 8.97001 255.86 7.57001 255.86 5.95001C255.86 4.33001 256.44 2.93001 257.61 1.76001C258.78 0.59001 260.18 0.0100098 261.8 0.0100098C263.42 0.0100098 264.82 0.59001 265.99 1.76001C267.16 2.93001 267.74 4.33001 267.74 5.95001ZM257.04 24.98C257.04 23.67 257.51 22.55 258.44 21.62C259.37 20.69 260.49 20.22 261.8 20.22C263.11 20.22 264.23 20.69 265.16 21.62C266.09 22.55 266.56 23.67 266.56 24.98V82.07C266.56 83.38 266.09 84.5 265.16 85.43C264.23 86.36 263.11 86.83 261.8 86.83C260.49 86.83 259.37 86.36 258.44 85.43C257.51 84.5 257.04 83.38 257.04 82.07V24.98Z" />
    <path
      d="M61.85 96.34C63.16 96.34 64.28 96.81 65.21 97.74C66.14 98.67 66.61 99.79 66.61 101.1C66.61 102.41 66.14 103.53 65.21 104.46C64.28 105.39 63.16 105.86 61.85 105.86H4.76C3.45 105.86 2.33 105.39 1.4 104.46C0.47 103.53 0 102.41 0 101.1C0 99.79 0.47 98.67 1.4 97.74C2.33 96.81 3.45 96.34 4.76 96.34H61.85Z"
      className="brand"
    />
  </svg>
)
